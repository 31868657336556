<template>
  <div>
    <div class="box">
      <el-page-header
        @back="$router.go(-1)"
        :content="content"
      ></el-page-header>
    </div>
    <el-card class="content mt20 bbb">
      <el-form
        label-position="left"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('pass.status')" prop="status">
           <el-radio-group v-model="ruleForm.status">
            <el-radio :label="0">{{$t('pass.status0')}}</el-radio>
            <el-radio :label="1">{{$t('pass.status1')}}</el-radio>
        </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('pass.username')" prop="username">
          <el-input
            v-model="ruleForm.username"
            :placeholder="$t('pass.username')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('pass.subject')" prop="subject">
          <el-input
            v-model="ruleForm.subject"
            :placeholder="$t('pass.subject')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('pass.row1')" prop="content[0].row">
          <el-input
            v-model="ruleForm.content[0].row"
            :placeholder="$t('pass.row1')"
          ></el-input>
          <el-radio-group v-model="ruleForm.content[0].size">
            <el-radio :label="0">{{$t('pass.size0')}}</el-radio>
            <el-radio :label="1">{{$t('pass.size1')}}</el-radio>
        </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('pass.row2')" prop="content[1].row">
          <el-input
            v-model="ruleForm.content[1].row"
            :placeholder="$t('pass.row2')"
          ></el-input>
          <el-radio-group v-model="ruleForm.content[1].size">
            <el-radio :label="0">{{$t('pass.size0')}}</el-radio>
            <el-radio :label="1">{{$t('pass.size1')}}</el-radio>
        </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('pass.row3')" prop="content[2].row">
          <el-input
            v-model="ruleForm.content[2].row"
            :placeholder="$t('pass.row3')"
          ></el-input>
          <el-radio-group v-model="ruleForm.content[2].size">
            <el-radio :label="0">{{$t('pass.size0')}}</el-radio>
            <el-radio :label="1">{{$t('pass.size1')}}</el-radio>
        </el-radio-group>
        </el-form-item>
         <el-form-item :label="$t('pass.url')" prop="pass_url">
          <el-input
            v-model="ruleForm.pass_url"
            :placeholder="$t('pass.url')"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button class="btn" type="primary" @click="submitForm('ruleForm')">{{
        $t("btnTip.submit")
      }}</el-button>
    </el-card>
  </div>
</template>
<script>
import { passDetail, passAdd, passEdit } from "@/api/index";
export default {
  name: "passEdit",
  data() {
    var validatePass = (rule, value, callback) => {
      const reg = /http[s]{0,1}:\/\/([\w.]+\/?)\S*/;
      if (reg.test(value) || value === '' || value === null) {
        callback();
      } else {
        callback(new Error(this.$t("carouselManagement").urlRule));
      }
    };
    return {
      ruleForm: {
        status: 0,
        username:'',
        subject:'',
        content:[
            {row:'',size:0},
            {row:'',size:0},
            {row:'',size:0},
        ],
        pass_url:''
      },
      rules: {
        status: [
          {
            required: true,
            message: this.$t('pass.status'),
            trigger: "blur",
          },
        ],
        username: [
          {
            required: true,
            message: this.$t('pass.username'),
            trigger: "blur",
          },
        ],
        subject: [
          {
            required: true,
            message: this.$t('pass.subject'),
            trigger: "blur",
          },
        ],
        'content[0].row': [
          {
            required: true,
            message: this.$t('pass.row1'),
            trigger: "blur",
          },
        ],
        'content[1].row': [
          {
            required: true,
            message: this.$t('pass.row2'),
            trigger: "blur",
          },
        ],
        'content[2].row': [
          {
            required: true,
            message: this.$t('pass.row3'),
            trigger: "blur",
          },
        ],
        pass_url: [
          {
            validator: validatePass,
            message: this.$t("carouselManagement").urlRule,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    id() {
      return Number.isNaN(Number(this.$route.query.id))
        ? undefined
        : Number(this.$route.query.id);
    },
    content() {
      return this.id === undefined
        ? this.$t("btnTip").add
        : this.$t("btnTip").edit;
    },
  },
  methods: {
    async submitForm(formName) {
      await this.$refs[formName].validate();
      if (this.id === undefined) {
        await passAdd({...this.ruleForm, pass_url: this.ruleForm.pass_url || null });
      } else {
        await passEdit({...this.ruleForm, pass_url: this.ruleForm.pass_url || null });
      }
      this.$router.go(-1);
    },
    async get_info() {
      const information = await passDetail(this.id);
      this.ruleForm = { ...information };
    },
  },
  async created() {
    if (this.id === undefined) return;
    this.get_info();
  },
};
</script>
<style land="scss" scoped>
.el-date-editor {
  margin-right: 10px;
}
.bbb {
  position: relative;
  padding-bottom: 50px;
}
.bbb .btn {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
</style>
